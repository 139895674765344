<template>
  <tr :class="{ 'checked-tr': isChecked }" @click="isChecked = !isChecked">
    <td>
      <w-check-box v-model="isChecked" class="row-check" />
    </td>
    <td class="text-normal">
      <div
        class="state-dot"
        :class="{
          approved: voucher.status == 'Approved',
          draft: voucher.status == 'Draft',
        }"
      ></div>
    </td>
    <td class="text-normal">
      <small>{{ voucher.voucherId }}</small>
    </td>
    <td class="text-normal">{{ voucher.voucherDate }}</td>
    <td class="text-normal">
      <small>{{ voucher.voucherType }}</small>
    </td>
    <td class="text-normal">
      <div class="d-flex justify-content-between">
        {{ voucher.referenceId
        }}<a href="#"> <feather-icon icon="DownloadIcon" /></a>
      </div>
    </td>
    <td class="text-normal">Vognmand 1</td>
    <td class="text-normal">Lokation A</td>
    <td class="text-right"><small>22,00</small></td>
    <td class="text-right"><small>17,333</small></td>
    <td class="text-right"><small>10.000,00 DKK</small></td>
    <td class="text-right"><small>5.000,00 DKK</small></td>
  </tr>
</template>

<script>
import WCheckBox from "./WCheckBox.vue";
export default {
  components: { WCheckBox },
  props: {
    voucher: {
      required: true,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.text-normal {
  color: #616161;
}
.text-highlight {
  color: #242424;
}

.state-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  &.approved {
    background-color: green;
  }
  &.draft {
    background-color: grey;
  }
}
.checked-tr {
  td {
    background-color: rgba(64, 126, 61, 0.12) !important;
  }
}
</style>