<template>
  <div>
    <input
      type="checkbox"
      :id="id"
      class="checkbox"
      @input="handleInput"
      v-model="value"
    />
    <label :for="id" class="round-label">
      <feather-icon v-if="value == true" icon="CheckIcon" />
    </label>
  </div>
</template>
<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { uuid } from 'vue-uuid';
export default {
  components: { FeatherIcon },
  props: ["value"],
  data() {
    return {
      id: uuid.v4(),
      content: this.value,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  position: relative;
  display: inline-flex;
  justify-content: center;
}
.round-label {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: 1px solid #616161;
  margin-bottom: 0;

  svg {
    position: absolute;
    height: 14px;
    width: 14px;
    left: 1px;
    top: 2px;
  }
}

/* Hide the default checkbox */
.checkbox {
  display: none;
}

.checkbox:checked + .round-label {
  background-color: #407e3d;
  color: white;
  border-color: #407e3d;
}

/* Checkmark */
/*
.round-label::after {
  content: "\2713"; 
  font-size: 16px;
  color: #616161; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
*/

/* Show checkmark when checkbox is checked */
.checkbox:checked + .round-label::after {
  opacity: 1;
}
</style>