<template>
  <div>
    <div class="w-table-wrap">
      <table>
        <tr>
          <th style="width: 52px"></th>
          <th style="width: 20px"></th>
          <th style="width: 70px">#</th>
          <th style="width: 120px">Bilagsdato</th>
          <th style="width: 130px">Bilagstype</th>
          <th style="width: 200px">Dokument-ID</th>
          <th style="width: 250px">Leverandør</th>
          <th style="width: 250px">Lokation</th>
          <th style="font-weight: normal" class="text-right">Antal tømning</th>
          <th style="font-weight: normal" class="text-right">Antal tons</th>
          <th style="font-weight: normal" class="text-right">Leje</th>
          <th style="font-weight: normal" class="text-right">Afgift/Indtægt</th>
        </tr>
        <template v-if="isLoading">
          <!--- loading animation --->
          <tr v-for="index in 5" :key="index" class="table-loader">
            <td><div class="loader-bar"></div></td>
            <td><div class="loader-bar"></div></td>
            <td><div class="loader-bar"></div></td>
            <td><div class="loader-bar"></div></td>
            <td><div class="loader-bar"></div></td>
            <td><div class="loader-bar"></div></td>
            <td><div class="loader-bar"></div></td>
          </tr>
          <!--- loading animation end --->
        </template>
        <template v-else>
          <tr class="month-divide-tr">
            <td class="month-divider-td" colspan="13">
              <span
                ><span>Marts 2024</span
                ><span class="voucher-month-count">4</span></span
              >
            </td>
          </tr>
          <voucher-list-table-row
            v-for="voucher in vouchers"
            :key="voucher.id"
            :voucher="voucher"
          />
          <tr class="month-divide-tr">
            <td class="month-divider-td" colspan="13">
              <span
                ><span>April 2024</span
                ><span class="voucher-month-count">2</span></span
              >
            </td>
          </tr>
          <voucher-list-table-row
            v-for="voucher in vouchers2"
            :key="voucher.id"
            :voucher="voucher"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import VoucherListTableRow from "./VoucherListTableRow.vue";
export default {
  components: { VoucherListTableRow },
  data() {
    return {
      selectedVouchers: [],
      isLoading: false,
      vouchers: [
        {
          voucherId: "0006",
          voucherDate: "10/03/2024",
          voucherType: "Faktura",
          referenceId: "3555225",
          status: "Draft",
        },
        {
          voucherId: "0005",
          voucherDate: "10/03/2024",
          voucherType: "Korrektionsnota",
          referenceId: "FF-2333",
          status: "Draft",
        },
        {
          voucherId: "0004",
          voucherDate: "10/03/2024",
          voucherType: "Kreditnota",
          referenceId: "552222",
          status: "Approved",
        },
        {
          voucherId: "0003",
          voucherDate: "10/03/2024",
          voucherType: "Vejeseddel",
          referenceId: "23334",
          status: "Approved",
        },
      ],
      vouchers2: [
        {
          voucherId: "0002",
          voucherDate: "10/04/2024",
          voucherType: "Faktura",
          referenceId: "3555225",
          status: "Approved",
        },
        {
          voucherId: "0001",
          voucherDate: "10/04/2024",
          voucherType: "Korrektionsnota",
          referenceId: "FF-2333",
          status: "Approved",
        },
      ],
    };
  },
  methods:{
    onVoucherSelected()
    {

    },
    onVoucherDeselected()
    {

    }
  }
};
</script>

<style lang="scss" scoped>
.w-table-wrap {
  display: table;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 12px;
  margin-bottom: 20px;
  overflow: hidden;
  table {
    width: 100%;
    ::v-deep {
      .text-right {
        text-align: right;
      }

      tr {
        height: 48px;

        .row-check {
          display: none;
        }

        th {
          border-bottom: 1px solid rgb(240, 240, 240);
          &:last-child {
            padding-right: 20px;
          }
        }

        td {
          padding: 0 10px;
          &:first-child {
            padding-top: 5px;
            text-align: center;
          }
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(13) {
            border-left: 1px solid rgb(240, 240, 240);
          }
        }

        &:not(:last-child) {
          td {
            border-bottom: 1px solid rgb(240, 240, 240);
          }
        }

        &:hover,
        &.checked-tr {
          .row-check {
            display: block;
          }
          td {
            background-color: #ebebeb;

            &:first-child {
              border-top-left-radius: 8;
            }
          }
        }

        &.table-loader {
          background-color: #f9f9f9;

          td {
            padding: 0 10px;
          }

          .loader-bar {
            width: 100%;
            height: 10px;
            background-color: #ececec;
            border-radius: 4px;
            position: relative;
            overflow: hidden;
          }

          .loader-bar::after {
            content: "";
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              90deg,
              transparent,
              rgba(255, 255, 255, 0.5),
              transparent
            );
            animation: loading 1.5s infinite;
          }

          @keyframes loading {
            0% {
              left: -100%;
            }
            50% {
              left: 100%;
            }
            100% {
              left: 100%;
            }
          }
        }

        &.month-divide-tr {
          height: 15px;
          td.month-divider-td {
            font-weight: 500;
            text-align: left;
            background-color: #f5f5f5;
            padding: 0px 20px;
            border-bottom: 1px solid #ebebeb;

            > span {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
</style>