<template>
  <b-row>
    <b-col cols="8">
      <vouchers-list-table />

      <!--
            <template v-for="(year, data) in voucherData">
                <div :key="year">
                    {{year}}
                </div>

            </template>
            -->
    </b-col>
    <b-col cols="4" v-if="true">
      <h3>Posteringsoverblik</h3>
      <div class="vouchers-stats-con">
        <table>
          <tr>
            <th>Infokort</th>
            <th>Antal tømning</th>
            <th>Antal tons</th>
            <th>Leje</th>
            <th>Affalds afgift/indtægt</th>
            <th>Div. udgifter og køb</th>
          </tr>
          <tr class="location-divider">
            <td colspan="6">Lokation A</td>
          </tr>
          <tr>
            <td>Pap</td>
            <td>22,00</td>
            <td>54,233</td>
            <td>3.000 DKK</td>
            <td class="chargeIncome">
              <span class="charge-text">3.300,00 DKK</span>
              <span class="income-text">300,00 DKK</span>
            </td>
            <td>0,00 DKK</td>
          </tr>
          <tr>
            <td>Metal</td>
            <td>33,00</td>
            <td>50,000</td>
            <td>3.000 DKK</td>
            <td class="chargeIncome">
              <span class="charge-text">3.300,00 DKK</span>
              <span class="income-text">300,00 DKK</span>
            </td>
            <td>0,00 DKK</td>
          </tr>
        </table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import VouchersListTable from "../components/VouchersListTable.vue";
export default {
  components: {
    BRow,
    BCol,
    VouchersListTable,
  },
  data() {
    return {
      voucherData: {
        2024: {
          1: [{ voucherNumber: "122" }],
          2: [],
        },
        2023: {
          1: [],
          2: [],
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.vouchers-stats-con {
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
  border-radius: 12px;
  padding: 20px;

  table {
    width: 100%;
    tr th {
      border-bottom: 2px solid;
    }
    tr th:not(:first-child) {
      border-left: 1px solid #d1d1d1;
      text-align: right;
    }
    tr td:not(:first-child) {
      border-left: 1px solid #d1d1d1;
      text-align: right;
    }

    tr td:not(:first-child, :last-child),
    tr th:not(:first-child, :last-child) {
      padding: 0px 10px;
    }
    tr th:first-child {
      padding-right: 10px;
    }
  }

  .chargeIncome {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  .location-divider {
    background-color: rgba(64, 126, 61, 0.12) !important;
    font-size: 11px;
    font-weight: bold;
  }
  .charge-text {
    color: red;
  }
  .income-text {
    color: green;
  }
}
body {
  background-color: #f0eff2;
}
</style>